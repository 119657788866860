<template>
  <div class="px-7 py-6" style="background: #f7f7f7; height: auto">
        <v-row>
          <v-col cols="8" md="4">
            <v-text-field
              hide-details=""
              outlined
              height="43px"
              dense
              v-model="districtDataTable[$API_KEYS.SEARCH]"
              label="Search by District or Sales Rep"
              append-icon="mdi-magnify"
              type="text"
              color="#38227A"
              class="search-input"
            >
            </v-text-field>
          </v-col>
          <v-col
            v-if="$vuetify.breakpoint.xsOnly && this.adminAccess==='Super Admin'"
            class=""
            align-self="center"
            cols="1"
         >
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="mobile-menu"
                  >mdi-menu</v-icon
                >
              </template>
              <v-list>
                <v-list-item-group active-class="active-class">
                  <v-list-item
                    @click="
                      toggleAddEditViewDistrictModal({
                        show: true,
                        type: 'add',
                      })
                    "
                  >
                    <v-list-item-title> Add District </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="route">
                    <v-list-item-title> Bulk Import </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-col>
          <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
          <v-col
            v-if="$vuetify.breakpoint.smAndUp  && (this.adminAccess==='Super Admin' ||'Sales Representative')"
            class="d-flex "
            cols="4"
            md="7"
            xl="3"
        >
            <v-btn
              text
              style="border: 1px solid #38227a; border-radius: 10px"
              class="px-11 mr-5 text-capitalize"
              @click="route"
              height="45px"
            >
              <span style="color: #38227a">Bulk import</span>
            </v-btn>
            <v-btn
              color="#38227A"
              dark
              @click="
                toggleAddEditViewDistrictModal({ show: true, type: 'add' })
              "
              class="px-11 text-capitalize"
              style="border-radius: 10px"
              height="45px"
            >
              <span>Add district</span>
            </v-btn>
          </v-col>
        </v-row>
        <br>
    <div >
      <v-data-table
        :options.sync="dataOptions"
        flat
        hide-default-footer
        :headers="districtDataTable.headers"
        :items="districtDataTable.items"
        :loading="districtDataTable.loading"
        :items-per-page="
          districtDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
        :server-items-length="districtDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]"
        class="elevation-2"
        loading-text="Loading... Please wait"
        mobile-breakpoint="0"
      >
        <template v-slot:item="row">
          <tr height="60px" class="text-flex">
            <td class="text-center row-item px-0">
              <v-row class="justify-center">
                <v-col  cols="10" md="12" lg="11" xl="8">
                  <div class="d-flex align-center" style="">
                    <div v-if="row.item.logo_url">
                      <img width="45" :src="row.item.logo_url" />
                    </div>
                    <div v-else>
                      <v-avatar color="#20BA9E">
                        <span class="user-initials-text">{{
                          row.item.district_name.substring(0, 2).toUpperCase()
                        }}</span>
                      </v-avatar>
                    </div>
                    <div class="ml-2">
                      <div>
                        <div
                          class="text-left font-weight-bold"
                          style="font-size: 14px"
                        >
                          {{ row.item.district_name }}
                        </div>
                      </div>
                      <div>
                        <div
                          class="text-left"
                          style="font-size: 12px; color: grey"
                        >
                          {{ row.item.city_name }}, {{ row.item.state }}
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </td>
            <td class="text-center row-item px-0">
              {{ row.item.total_school }}
            </td>
            <td class="text-center row-item px-0">
              {{ row.item.total_campaign }}
            </td>
          <td class="text-center row-item">
  $ {{ row.item.total_fund_raised }}
</td>

<td class="text-center row-item px-0">
  {{ row.item.last_wizfit }}
</td>
            <td class="text-center row-item px-0">
              {{ row.item.sales_rep_name }}
            </td>
            <td class="text-right row-item">
              <v-row class="justify-center">
                <v-col class="pa-0 text-center" cols="12" xl="8">
                  <v-chip class="actionColumn-btn" color="#F2F2F2"
                  @click="viewPublicDonationPageHandler(row.item.campaign_detail)">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dark
                          size="22"
                          v-bind="attrs"
                          v-on="on"
                          class="icons"
                          color="#6B6B6B"
                        >
                          mdi-open-in-new
                        </v-icon>
                      </template>
                      <span>Public Page</span>
                    </v-tooltip>
                  </v-chip>
                  <v-chip
                    color="#F2F2F2"
                    class="actionColumn-btn"
                    @click="visitDistrictCampaign(row.item)"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dark
                          size="22"
                          v-bind="attrs"
                          v-on="on"
                          class="icons"
                          color="#6B6B6B"
                        >
                          mdi-eye
                        </v-icon>
                      </template>
                      <span>Dashboard</span>
                    </v-tooltip>
                  </v-chip>

                  <v-menu offset-y left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        class="actionColumn-btn"
                        color="#F2F2F2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon size="25" color="#6B6B6B"
                          >mdi-dots-horizontal</v-icon
                        >
                      </v-chip>
                    </template>
                    <v-list class="menu-list moreOptionsStyle py-0">
                      <v-list-item-group class="py-0">
                        <v-list-item
                          style="min-height: 40px"
                          @click="
                            toggleAddEditViewDistrictModal({
                              show: true,
                              type: 'edit',
                              id: row.item.id,
                            })
                          "
                       v-if="row.item.user_type=='Super Admin'||'Sales Representative' || 'Event Support Staff'" >
                          <v-icon left size="18">mdi-pencil</v-icon>
                          <v-list-item-title style="font-size: 14px">
                            Edit District
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="startNewCampaignHandler(row.item)"
                          style="
                            min-height: 40px;
                            border-top: 1px solid #d2d2d2;
                          "
                        v-if="row.item.user_type=='admin'" >
                          <v-img
                            src="@/assets/menuCampaignLogo.png"
                            width="17px"
                            class="mr-2"
                          ></v-img>
                          <v-list-item-title style="font-size: 14px">
                            Start a New Campaign
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="viewCampaignHandler(row.item)"
                          style="
                            min-height: 40px;
                            border-top: 1px solid #d2d2d2;
                          "
                        >
                          <v-img
                            src="@/assets/menuCampaignViewLogo.svg"
                            width="17px"
                            class="mr-2"
                          ></v-img>
                          <v-list-item-title style="font-size: 14px">
                            View Campaigns
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="addSchoolHandler(row.item)"
                          style="
                            min-height: 40px;
                            border-top: 1px solid #d2d2d2;
                          "
                       v-if="row.item.user_type=='admin'||'Sales Representative' || 'Event Support Staff'"   >
                          <v-img
                            src="@/assets/Navigation/school.svg"
                            width="17px"
                            class="mr-2"
                          ></v-img>
                          <v-list-item-title style="font-size: 14px">
                            Add School
                          </v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </v-col></v-row
              >
            </td>
          </tr>
        </template>
        <template
          v-slot:footer
          v-if="districtDataTable.items[0] && $vuetify.breakpoint.smAndUp"
        >
          <div>
            <v-divider></v-divider>
            <v-row no-gutters class="pt-6 px-2">
              <v-col
                class="mb-4"
                align-self="center"
                cols="12"
                xs="12"
                sm="6"
                md="4"
              >
                <div
                  class="
                    justify-center justify-md-start
                    d-flex
                    px-5
                    tableHeader-text
                  "
                >
                  <div class="alignSelf-center mr-3">Show</div>
                  <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                    <v-select
                      class=""
                      :items="itemsPerPageList"
                      hide-details
                      v-model="
                        districtDataTable[$API_KEYS.PAGE_INFO][
                          $API_KEYS.ITEMS_PER_PAGE
                        ]
                      "
                      dense
                    ></v-select>
                  </div>

                  <div class="alignSelf-center">
                    {{
                      districtDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.PAGE_NUMBER
                      ]
                    }}
                    of
                    {{
                      districtDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.TOTAL_PAGE
                      ]
                    }}
                  </div>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" xs="12" sm="4" md="4">
                <div>
                  <v-pagination
                    v-model="
                      districtDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.PAGE_NUMBER
                      ]
                    "
                    :length="
                      districtDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.TOTAL_PAGE
                      ]
                    "
                    :total-visible="5"
                    color="#D30024"
                    class="pagination"
                  >
                  </v-pagination>
                </div>
              </v-col>
            </v-row>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-card
      v-if="districtDataTable.items[0] && $vuetify.breakpoint.xsOnly"
      flat
      class="footer-card"
    >
      <v-card-text>
        <v-row no-gutters>
          <v-col
            class="mb-4"
            align-self="center"
            cols="12"
            xs="12"
            sm="6"
            md="4"
          >
            <div
              class="
                justify-center justify-md-start
                d-flex
                px-5
                tableHeader-text
              "
            >
              <div class="alignSelf-center mr-3">Show</div>
              <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                <v-select
                  class=""
                  :items="itemsPerPageList"
                  hide-details
                  v-model="
                    districtDataTable[$API_KEYS.PAGE_INFO][
                      $API_KEYS.ITEMS_PER_PAGE
                    ]
                  "
                  dense
                ></v-select>
              </div>

              <div class="alignSelf-center">
                {{
                  districtDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                }}
                of
                {{
                  districtDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                }}
              </div>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="4" md="4">
            <div>
              <v-pagination
                v-model="
                  districtDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                "
                :length="
                  districtDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                "
                :total-visible="5"
                color="#D30024"
                class="pagination"
              >
              </v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-card-text> </v-card
    ><br />
    <add-edit-view-district-modal
      :DistrictFormData="DistrictFormData"
      @reload="getDistrictManagementData()"
    ></add-edit-view-district-modal>
  </div>
</template>
<script>
// import { ROUTER_URL } from "@/constants/urls";
import { API_ADMIN_DISTRICT_MANAGEMENT_LIST } from "@/constants/APIUrls";
import { mapActions } from "vuex";
import { ROUTER_URL } from "@/constants/urls";
import Axios from "@/api/BaseAxios";
import _ from "lodash";
import Helper from "@/helper";
import {
  DATA,
  PAGE_INFO,
  DISTRICT_LIST,
  ITEMS_PER_PAGE,
  PAGE_NUMBER,
  TOTAL_PAGE,
  SEARCH,
  SORTING_KEY,
  CAMPAIGN_TYPE,
  ID,
  CAMPAIGN_ID,
  HOST_NAME,
  HOST_EMAIL,
} from "@/constants/APIKeys";
import { ITEMS, LOADING, SALES_REP } from "@/constants/ModuleKeys";
export default {
  name: "DistrictManagement",
  components: {
    AddEditViewDistrictModal: () =>
      import("./AddEditViewDistrictModal/AddEditViewDistrictModal.vue"),
  },
  data() {
    return {
    adminAccess:localStorage.getItem("adminAccess"),
      dataOptions: { sortBy: [], sortDesc: [] },
      districtDataTable: {
        headers: [
          {
            width: "23%",
            sortable: true,
            text: "District Info",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg"],
            value: "district_name",
          },
          {
            sortable: true,
            text: "# Schools",
            align: "center",
            value: "total_school",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            sortable: true,
            text: "# Campaigns",
            align: "center",
            value: "total_campaign",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            sortable: true,
            text: "Total Funds Raised",
            align: "center",
            value: "total_fund_raised",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            sortable: true,
            text: "Last Wizfit",
            value: "last_wizfit",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            sortable: true,
            text: "Sales REP",
            value: "sales_rep__name",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
          {
            width: "20%",
            sortable: false,
            text: "Actions",
            value: "icon",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
        ],
        [ITEMS]: [],
        [LOADING]: true,
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: -1,
          [ITEMS_PER_PAGE]: 10,
        },
        [SEARCH]: "",
        [SORTING_KEY]: "",
      },
      itemsPerPageList: [10, 15, 20, 30, 50, 100],
      DistrictFormData: {
        flag: false,
        loading: false,
        disabled: false,
        type: "add",
        item: [],
      },
    };
  },
  computed: {},
  watch: {
    //Watcher for detecting change in PAGE_NUMBER
    "districtDataTable.page_info.page_number": function () {
      this.getDistrictManagementData();
    },
    //Watcher for detecting change in ITEMS_PER_PAGE
    "districtDataTable.page_info.detail_count": function () {
      this.getDistrictManagementData();
    },
    //Watcher for detecting change in SEARCH
    "districtDataTable.search": function () {
      this.debounceGetDistrictManagementData();
    },
    showModal: function (value) {
      console.log(value);
    },
    "dataOptions.sortBy": function () {
      if (this.dataOptions.sortBy.length !== 0) {
        this.districtDataTable[SORTING_KEY] = this.dataOptions.sortDesc[0]
          ? `-${this.dataOptions.sortBy[0]}`
          : this.dataOptions.sortBy[0];
        this.getDistrictManagementData();
      } else {
        this.districtDataTable[SORTING_KEY] = "";
        this.getDistrictManagementData();
      }
    },
  },
  methods: {
    ...mapActions({
      // district Modal
      toggleAddEditViewDistrictModal: "districtManagement/toggleModal",
      showToast: "snackBar/showToast",
    }),
    route() {
      // this.$router.push({
      //   name: ROUTER_URL.adminPanel.children.bulkUploadDistrict.name,
      // });
      this.$root.$refs.adminLayout.route("district");
    },
    getDistrictManagementData() {
      const self = this;
      this.districtDataTable.loading = true;
      console.log("inside getDistrictManagementData");
      const successHandler = (res) => {
        console.log(
          "ITEMS_PER_PAGE: ",
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE],
          "PAGE_NUMBER: ",
          res[DATA][PAGE_INFO][PAGE_NUMBER],
          "TOTAL_PAGE: ",
          res[DATA][PAGE_INFO][TOTAL_PAGE],
          "DISTRICT_LIST: ",
          res[DATA][DISTRICT_LIST]
        );
        self.districtDataTable.items = res[DATA][DISTRICT_LIST];
        self.districtDataTable[PAGE_INFO][PAGE_NUMBER] =
          res[DATA][PAGE_INFO][PAGE_NUMBER];
        self.districtDataTable[PAGE_INFO][TOTAL_PAGE] =
          res[DATA][PAGE_INFO][TOTAL_PAGE];
        self.districtDataTable[PAGE_INFO][ITEMS_PER_PAGE] =
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE];
        self.districtDataTable.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.districtDataTable.loading = false;
      };
      let dataTableParams = {};
      dataTableParams[SEARCH] = this.districtDataTable[SEARCH];
      if (this.districtDataTable[PAGE_INFO][ITEMS_PER_PAGE] == 0) {
        dataTableParams[ITEMS_PER_PAGE] = 10;
      } else {
        dataTableParams[ITEMS_PER_PAGE] =
          this.districtDataTable[PAGE_INFO][ITEMS_PER_PAGE];
      }
      if (this.districtDataTable[PAGE_INFO][PAGE_NUMBER] == 0) {
        dataTableParams[PAGE_NUMBER] = 1;
      } else {
        dataTableParams[PAGE_NUMBER] =
          this.districtDataTable[PAGE_INFO][PAGE_NUMBER];
      }
      dataTableParams[SORTING_KEY] = this.districtDataTable[SORTING_KEY];

      Axios.request_GET(
        API_ADMIN_DISTRICT_MANAGEMENT_LIST,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    startNewCampaignHandler(campaign) {
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.newCampaign.name,
        query:{type:"add"},
        params: {
          [CAMPAIGN_TYPE]: "district",
          [SALES_REP]: campaign[SALES_REP],
          [ID]: campaign[ID],
          [HOST_NAME]: campaign[HOST_NAME],
          [HOST_EMAIL]: campaign[HOST_EMAIL],
        },
      });
    },
    viewCampaignHandler(campaign) {
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.campaignManagement.name,
        params: { search: campaign.district_name },
      });
    },
    visitDistrictCampaign(district) {
      const { campaign_detail, id } = district;
      if (campaign_detail) {
        let routeData = this.$router.resolve({
          name: ROUTER_URL.districtPanel.children.dashboard.name,
          query: { district: id, [CAMPAIGN_ID]: campaign_detail.id },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.showToast({
          message: "This District isn't associated with any campaigns yet",
          color: "e",
        });
      }
    },
    addSchoolHandler(item) {
      console.log(item);
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.schoolManagement.name,
        params: {
          [SALES_REP]: item[SALES_REP],
          [ID]: item[ID],
        },
      });
    },
    viewPublicDonationPageHandler(item) {
      if(item){
        const { sub_domain } = item;
      Helper.openPublicDonationPage(sub_domain);
      }else{
        this.showToast({
          message: "This District isn't associated with any campaigns yet",
          color: "e",
        });
      }
    },
  },
  mounted() {
    this.getDistrictManagementData();
  },
  created() {
    this.debounceGetDistrictManagementData = _.debounce(
      this.getDistrictManagementData,
      500
    );
  },
};
</script>

<style scoped>
* {
  font-family: Lato;
}
.active-class {
  border-right: 4px solid #38227a;
  border-left: 4px solid #38227a;
  border-radius: 4px;
}
.alignSelf-center {
  align-self: center;
}
.card-datatable.v-card {
 
  margin-bottom: -2px;
  display: flex;
}
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
.footer-card.v-card {
  max-height: 70px;
  margin-top: 0px;
  border-top: 1px solid #e6e6e6;
}
.trow {
  height: 50px;
  background-color: #eeeeee;
}
.v-btn >>> span {
  letter-spacing: 0px;
  font-weight: 600;
}
.tableHeader-text {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 161.5%;
  color: #1d1d1d;
}
.user-initials-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 315%;
  color: white;
  letter-spacing: 0px;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}
.v-list.menu-list {
  border: 1px solid #d2d2d2;
  box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
.v-menu__content.theme--light.menuable__content__active {
  border-radius: 6px;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  font-family: Lato;
}
.pagination {
  float: right;
}
@media (min-width: 0px) and (max-width: 600px) {
  .pagination {
    float: none;
  }
  .footer-card.v-card {
    max-height: 200px;
    align-items: center;
    text-align: center;
  }
  .v-input.search-input {
    border-radius: 6px;
    box-sizing: border-box;
  }
  .v-data-table-header {
    display: flex;
    width: 100%;
    overflow-x: scroll;
  }
  .table-div {
    overflow: scroll;
  }
  .v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
    min-width: 1100px;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
span.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
  height: 45px;
  width: 38px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
@media (max-width: 768px) {
  .row-item {
    white-space: nowrap; /* Prevents text from breaking */
    overflow: hidden; /* Ensures it doesn't spill over */
    text-overflow: ellipsis; /* Shows '...' if text overflows */
    padding-right: 15px;
  }
}

.row-item {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
